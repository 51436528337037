<template>
  <div style="  display: -webkit-flex; /* Safari */
display: block;background: #000000;opacity: 0.9;width: 100%;height: 100%;align-items: flex-start;flex-direction: column;z-index: 999">


    <img src="../assets/ic_zs_arrow.png" width="55px" height="64px"
         style="align-self: flex-end;margin-top: 54px;margin-right: 36px">

    <div class="line" style="margin-top: 40px">
      <div class="dot"><span>1</span></div>
      <p style="margin-left: 30px">点击右上角的按钮</p>
    </div>
    <div class="line" style="margin-top: 80px">
      <div class="dot"><span>2</span></div>
      <p style="margin-left: 20px">选择</p> <img style="margin-left: 20px" src="../assets/ic_open_in_llq.png"
                                               width="163px" height="33px">
    </div>

    <div class="line-button">知道了</div>

  </div>
</template>

<script>
// import mp4Path from "../assets/background_video.mp4"
// import webmPath from "../../assets/background/hanging/Hanging.webm"
// import jpegPath from "../assets/background_pc.jpg"

export default {
  name: "DownLoadForWX",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style>

.line {
  display: block;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-style: normal;
  margin-left: 80px;
  font-family: PingFang SC;
  font-size: 26px;
  font-weight: 500;
  color: white;
}

.line p {
  font-size: 38px;
  color: white;
}

.dot {
  text-align: center;
  font-size: 26px;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 25px;
  background: linear-gradient(#9929ea, #5808fb);
}

.dot span {
  line-height: 50px;
  vertical-align: middle;
}

.line-button {
  width: 432px;
  height: 90px;
  border-radius: 45px;
  background: linear-gradient(#9929ea, #5808fb);
  font-family: PingFang SC;
  font-size: 38px;
  align-self: center;
  margin-top: 210px;
  color: white;
  text-align: center;
  line-height: 90px;
  font-weight: 500;
  vertical-align: middle;
}
</style>
