<template style="width: 100%;height: 100%;">
  <div style="width: 100%;height: 100%;">
    <img src="../assets/bg.jpg" style="position: absolute; z-index: 0;width: 100%;height: 100%;object-fit: cover">

    <!--    <div style="position: absolute;width: 100%;height: 100%;" class="container-bg"></div>-->
    <!--    <div style="position: absolute;width: 100%;height: 100%;background-color: rgba(0,0,0,0.2)"></div>-->


    <div style="display: flex;flex-direction: row;align-items: start;position: absolute;left: 100Px;top: 82Px;">
      <img src="../assets/logo-pc.png" style="width: 240Px;margin-bottom: 20Px;border-radius: 15Px;">
    </div>


    <div style="display: flex;position: absolute;top: 176Px;right: 120Px;flex-direction: column">

      <img src="../assets/title-pc.png" style="width: 580Px;margin-bottom: 20Px">


      <div
          style="display: flex;flex-direction: column;justify-content: center;align-items: center;">

        <div
            style="display: flex;flex-direction: column;align-items: center;width: 464px;height: 351px;margin-top: 15px;position: relative;">
          <!--          <a :href="iosDownloadUrl" style="margin-bottom: 15Px">-->
          <!--            <div style="width: 180Px;height: 49Px;background: linear-gradient(#FF2E8B,#FF277A);border-radius: 5Px;display: flex;flex-direction: row;align-items: center;justify-content: center">-->
          <!--              <img src="../assets/ic_ios_log.png" style="width: 16Px;">-->
          <!--              <p style="color: #FFFFFF;margin-left: 10Px;font-size: 16Px">iOS下载</p>-->
          <!--            </div>-->
          <!--          </a>-->

          <img src="../assets/qrcode_bg.png" width="360px">

          <div style="position: absolute;top: 80px; background-color: #FFFFFF;padding: 8Px;border-radius: 5Px;"
               v-qr="options" v-if="options"/>
        </div>

        <div style="color: #FFFFFF;font-size: 18px;">建议用 Chrome 、夸克、 UC浏览器 扫码打开</div>
        <!--        <div style="display: flex;flex-direction: column;align-items: center;margin-left: 30Px">-->
        <!--          <a :href="androidDownloadUrl" style="margin-bottom: 15Px">-->
        <!--            <div style="width: 180Px;height: 49Px;background: linear-gradient(#FF2E8B,#FF277A);border-radius: 5Px;display: flex;flex-direction: row;align-items: center;justify-content: center">-->
        <!--              <img src="../assets/ic_android_log.png" style="width: 16Px;">-->
        <!--              <p style="color: #FFFFFF;margin-left: 10Px;font-size: 16Px">Android下载</p>-->
        <!--            </div>-->
        <!--          </a>-->
        <!--          <div style="background-color: #FFFFFF;padding: 8Px;border-radius: 5Px;" v-qr="options" v-if="options"/>-->
        <!--        </div>-->
      </div>

    </div>

    <div
        style="position: absolute;bottom:30Px;width: 100%;display: flex;flex-direction: row;justify-content: center;font-size: 16Px;color: white">
      由于某些原因，本APP被某些软件认定为病毒，请大家放心使用，本APP含有广告收益，且已盈利，
      安装本APP不会对您的手机造成任何损害
    </div>

    <a :href="tgUrl"
       style="border-radius: 4Px; position: absolute;right: 10Px;top:10Px;height: 20Px;color:white;font-size: 14Px;background:linear-gradient(#FF2E8B,#FF277A);padding: 5Px 10Px;display: flex;flex-direction: row;align-items: center;">
      <img src="../assets/tg.png" width="13">
      <span style="margin-left: 10Px;">商务合作</span>
    </a>

  </div>


</template>

<script>
// import mp4Path from "../assets/background_video.mp4"
// import webmPath from "../../assets/background/hanging/Hanging.webm"
// import jpegPath from "../assets/background_pc.jpg"
export default {
  name: "DownLoadForPC",
  data() {
    return {
      androidDownloadUrl: "",
      iosDownloadUrl: "",
      tgUrl: "",
      options: {
        text: window.location.href,
        render: "canvas",
        width: 150,
        height: 150,
        typeNumber: -1,
        correctLevel: 2,
        background: "#ffffff",
        foreground: "#000000"
      },
      inviteCode: "",
    }
  },
  methods: {

    downloadIOS: function () {
      console.log("downloadIOS");
      window.open(this.iosDownloadUrl);
    },

    downloadAndroid: function () {
      console.log("downloadAndroid");
      window.open(this.androidDownloadUrl);
    },
  },
  created() {

    this.$axios.get('/config.json').then(res => {
      console.log(res);
      this.androidDownloadUrl = res.data.android_url;
      this.iosDownloadUrl = res.data.ios_url;
      this.tgUrl = res.data.telegram_url;
      console.log(this.androidDownloadUrl);

    });
  },
  mounted: function () {

  }
}
</script>

<style>

.login_img .divImg {
  background-position: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.swiper-img {
  background: red;
  width: 100%;
  height: 100%;
}
</style>
