import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import DownloadResume from '../components/DownLoadResume.vue'
import DownloadPage from '../components/DownloadPage.vue'
import DownLoadForWebClip from '../components/DownloadForWebCilp.vue'
import DownLoadAndroidResume from '../components/DownloadAndroidResume.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'DownloadPage',
        component: DownloadPage
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: '/DownloadResume',
        name: 'DownloadResume',
        component: DownloadResume
    },
    {
        path: '/DownLoadForWebClip',
        name: 'DownLoadForWebClip',
        component: DownLoadForWebClip
    },
    {
        path: '/DownLoadAndroidResume',
        name: 'DownLoadAndroidResume',
        component: DownLoadAndroidResume
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
