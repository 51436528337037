<template>
	<div class="download-appstore">
		<div class="header">
			<div class="title">色友App . 安装说明</div>
		</div>
		<div class="main">
			<div class="step">
				<div class="step-title">第一步</div>
				<div class="step-content">
					<p style="margin-bottom: 10Px">下载【TestFlight】，然后 <span class="danger">返回本页面</span>，操作第二步</p>
					<a class="img-logo" href="https://apps.apple.com/cn/app/testflight/id899247664">
						<img src="../assets/testflight_logo.png" alt="">
					</a>
					<p class="btn"><a href="https://apps.apple.com/cn/app/testflight/id899247664">安装苹果TestFlight</a>
					</p>
				</div>
			</div>
			<div class="step">
				<div class="step-title">第二步</div>
				<div class="step-content" style="text-align: center">
					<p>点【下载官方版】 ，安装好后，点开始测试。</p>
					<p style="color:red;margin-bottom: 10Px">如果提示测试人数已满，请选择其他马甲App下载</p>
					<div style="display: inline-block;width: 48%;">
						<a class="img-logo img-logo-shadow copy" :href="ios_tf_url">
							<img src="../assets/ic_app_logo.png" alt="">
						</a>
						<p class="center">色友</p>
						<p class="btn"><a :href="ios_tf_url"
								class="copy">下载官方版1</a></p>
					</div>
					<div style="display: inline-block;width: 48%;">
            <a class="img-logo img-logo-shadow copy" :href="ios_tf_url">
              <img src="../assets/ic_app_logo.png" alt="">
            </a>
						<p class="center">色友</p>
						<p class="btn"><a :href="ios_tf_url"
								class="copy">下载官方版2</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		data() {
			return {
				ios_tf_url: null,
				ios_sign_url: null
			};
		},
		created() {
			this.$axios.get('/config.json').then(res => {
				this.ios_tf_url = res.data.ios_tf_url;
				this.ios_sign_url = res.data.ios_sign_url;
			});
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.resume {
		width: 100%;
		height: 100%;
		position: fixed;
		background-position: left top;
		margin: 0 auto;
		background-color: #ffffff;
		/* background-image: url(../assets/bg_pc.png); */
		background-repeat: no-repeat;
		background-size: cover;
	}

	body {}

	.download-appstore {
		overflow-y: auto;
		padding-bottom: 100px;
		background-color: #FFFFFF;
	}

	.header {
		text-align: center;
		padding: 20px 0;
		margin-bottom: 20px;
		background-color: #f5f5f5;
	}

	.header .title {
		padding: 10px 0;
		color: #333;
		font-size: 32px;
	}

	.header .subject {
		font-size: 42px;
		color: #ff0000;
		padding: 15rem 0;
	}

	.main .step .step-title {
		font-size: 42px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 10px;
	}

	.main .step .step-content {
		padding: 0 20px;
	}

	.main .step .step-content p {
		padding: 10px 0;
		font-size: 28px;
		color: #666;
	}

	.main .step .step-content p.center {
		text-align: center;
	}

	.main .step .step-content p>img {
		width: 100%;
	}

	.main .step .step-content p.img-logo {
		display: block;
		text-align: center;
	}

	.main .step .step-content a.img-logo {
		display: block;
		text-align: center;
	}

	.main .step .step-content .img-logo img {
		display: inline-block;
		width: 200px;
		height: 200px;
	}

	.main .step .step-content .img-logo-shadow img {
		border-radius: 10px;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	}

	.main .step .step-content .img-shadow {
		margin: 10px;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
	}

	.main .step .step-content p.btn {
		text-align: center;
		margin: 0 0 20px 0;
	}

	.main .step .step-content p.btn a {
		display: inline-block;
		padding: 15px 20px;
		border-radius: 36px;
		color: #fff;
		background: linear-gradient(#17BEFA, #1B78F3);
	}

	.danger {
		font-size: 30px;
		color: red;
	}
</style>
