import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible/flexible'
import './assets/style/reset.css'
Vue.config.productionTip = false
import qrcode from 'vue-qrcode-directive'
Vue.use(qrcode)
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);
Vue.config.productionTip = false
import axios from 'axios' // 安装axios后引入
Vue.prototype.$axios = axios // 将axios挂载到原型上方便使用
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.prototype.getConfig = function() {
	// eslint-disable-next-line no-unused-vars
	this.$http.get('./static/config.json').then(_ => {
		// console.log("config,js");
		// console.log(res);
		// eslint-disable-next-line no-unused-vars
	}).catch(_ => {
		// console.log("config,jse");
		// console.log(err)
	})
}

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
