<template>
	<DownLoadForMobile v-if="isMobile == true" style="width: 100%;height: 100%;"/>
	<DownLoadForPC v-else style="width: 100%;height: 100%;" />
</template>
<script type="text/javascript">
	import DownLoadForPC from './DownloadForPC.vue'
	import DownLoadForMobile from './DownLoadForMobile.vue'

	export default {
		name: 'app',
		data() {
			return {
				isMobile: false,
				config:{}
			};
		},
		components: {
			// DownloadForPc,
      DownLoadForPC,
			DownLoadForMobile
		},
		methods: {
			checkIsMobile: function() {
				const flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				if (flag) {
					return true;
				} else {
					return false;
				}
			}
		},
		created() {
			this.isMobile = this.checkIsMobile();
		},
	}
</script>
<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		font-size: 0;
		background-color: red;
	}
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
</style>