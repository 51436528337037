<template>
  <div style="display: flex;background: #F5F5F5;flex-direction: column;align-items: center;padding-bottom: 40px;width: 100%;height: 100%">

    <img src="../assets/close.png" width="25px" height="25px" style="position: absolute;right: 20px;top: 20px;"
         v-on:click="closePage">

    <span
        style="color:#333333;font-weight: normal;font-size: 18Px;margin-top: 40px;">手机报毒禁止安装解决办法</span>

    <div v-for="(item, index) in source" :key="index"
         style="display: flex;flex-direction: column;width: 100%;align-items: center;margin-top: 15px">

      <div class="item-card" style="height: 50px;">
        <img :src="item.icon" width="25px" height="25px"/>
        <span style="flex-grow: 1;margin-left: 20px">{{ item.title }}</span>
        <div v-show="index != checkIndex"
            style="border-radius: 99px;border: 1px solid #ffc12f;padding: 4px 12px;font-size: 15px;color: #ffc12f;"
            v-on:click="onChangeIndex(index)">
          查看
        </div>
      </div>

      <div v-if="index == checkIndex" v-for="(d, i) in item.desc" :key="i"
           style="display: flex;flex-direction: column;align-items: center;margin: 10px 20px">
        <div style="display: flex;flex-direction: row;align-items: center;margin-bottom: 10px">
          <div
              style="color: #FFFFFF;background-color: red;width: 15px;height: 15px;display: flex;align-items: center;justify-content: center;border-radius: 30px;margin-right: 5px;flex-shrink: 0;">
            {{ i + 1 }}
          </div>
          <div class="item-desc-item" v-html="d.desc" style="flex-wrap: wrap;"></div>
        </div>
        <img :src="d.img" width="100%">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownLoadAndroidResume",
  data() {
    return {
      target:"",
      checkIndex: -1,
      source: [
        {
          "icon": require("../assets/green-setting.png"),
          "title": "允许未知来源APP安装设置",
          "select": false,
          "desc": [
            {
              "desc": "下载前请先至 <span>【设置】</span><span>【系统安全】</span>",
              "img": require("../assets/green-setting-image1.png")
            },
            {
              "desc": "允许未知来源",
              "img": require("../assets/green-setting-image2.png")
            },
          ]
        },
        {
          "icon": require("../assets/huawei.png"),
          "title": "华为手机安装APP和杀毒设置",
          "select": false,
          "desc": [
            {
              "desc": "下载后会弹出风险框，请不用担心， 本App  没有任何病毒，请点击 <span>【暂不处理】</span>",
              "img": require("../assets/huawei-image1.png")
            },
            {
              "desc": "安装后打开<span>【信任此应用】</span>",
              "img": require("../assets/huawei-image2.png")
            },
            {
              "desc": "找到<span>【禁止安装恶意应用】</span>并关闭",
              "img": require("../assets/huawei-image3.png")
            },
          ]
        },
        {
          "icon": require("../assets/harmonyOS.png"),
          "title": "鸿蒙纯净版安装APP和杀毒设置",
          "select": false,
          "desc": [
            {
              "desc": "无法安装APP点击 <span>【取消】</span>",
              "img": require("../assets/harmonyOS-image1.png")
            },
            {
              "desc": "打开手机设置页面点击<span>【系统和更新】</span>",
              "img": require("../assets/harmonyOS-image2.png")
            },
            {
              "desc": "点击<span>【纯净模式】</span>",
              "img": require("../assets/harmonyOS-image3.png")
            },
            {
              "desc": "点击<span>【推出】</span>按钮",
              "img": require("../assets/harmonyOS-image4.png")
            }, {
              "desc": "点击<span>【仍然退出】</span>",
              "img": require("../assets/harmonyOS-image5.png")
            }, {
              "desc": "回到安装页面，点击<span>【继续安装】</span>",
              "img": require("../assets/harmonyOS-image6.png")
            }, {
              "desc": "点击<span>【继续安装】</span>",
              "img": require("../assets/harmonyOS-image7.png")
            }, {
              "desc": "点击<span>【解除】</span>解除网络限制",
              "img": require("../assets/harmonyOS-image8.png")
            },
          ]
        },
        {
          "icon": require("../assets/mi.png"),
          "title": "小米手机安装APP和杀毒设置",
          "select": false,
          "desc": [
            {
              "desc": "小米手机在正常安装情况下不会提示病毒，但 下载后我们还是需在腾讯手机管家里把 App 添加到<span>【信任区】</span>",
              "img": require("../assets/mi-image1.png")
            },
            {
              "desc": "继续下一步，点击<span>【处理】</span>",
              "img": require("../assets/mi-image2.png")
            },
            {
              "desc": "然后点击<span>【移入信任区】</span>",
              "img": require("../assets/mi-image3.png")
            },
            {
              "desc": "点击<span>【确认】</span>后，白名单添加成功，再次杀毒就不会提示了",
              "img": require("../assets/mi-image4.png")
            },
          ]
        },
        {
          "icon": require("../assets/oppo-header.png"),
          "title": "OPPO手机安装APP和杀毒设置",
          "select": false,
          "desc": [
            {
              "desc": "下载后会弹出<span>发现病毒</span>请不用担心，本App 没有任何病毒，请点击<span>【无视风险安装】</span>",
              "img": require("../assets/mi-image1.png")
            },
            {
              "desc": "OPPO自带的<span>【安全卫士】</span>中会检测出敏感内容 由于 本App 中包含成人内容所致请放心使用",
              "img": require("../assets/mi-image2.png")
            }
          ]
        },
        {
          "icon": require("../assets/vivo.png"),
          "title": "VIVO手机安装APP和杀毒设置",
          "select": false,
          "desc": [
            {
              "desc": "下载后会弹出风险框，请点击<span>【无视风险安装】</span>",
              "img": require("../assets/vivo-image1.png")
            },
            {
              "desc": "在<span>【高风险提示】</span>弹窗出现后点击<span>【安装】</span>,弹框出现后点击安装，如没有安装，请重复第一步并点击<span>【忽略风险并安装】</span>",
              "img": require("../assets/vivo-image2.png")
            }
          ]
        },
        {
          "icon": require("../assets/meizu.png"),
          "title": "魅族手机安装APP和杀毒设置",
          "select": false,
          "desc": [
            {
              "desc": "在<span>【高风险提示】</span>弹框出现后点击安装，如没有安装，请重复第一步并点击<span>【忽略风险并安装】</span>",
              "img": require("../assets/meizu-image1.png")
            },
            {
              "desc": "检测完成后请点击<span>【隐患应用】</span>，不用点击<span>【立即修复】</span>",
              "img": require("../assets/meizu-image2.png")
            },
            {
              "desc": "进入后再点击<span>【受信任的应用】</span>",
              "img": require("../assets/meizu-image3.png")
            },
            {
              "desc": "找到 雏女VLog，然后勾选点击<span>【确认】</span>",
              "img": require("../assets/meizu-image4.png")
            }
          ]
        },
        {
          "icon": require("../assets/tencent.png"),
          "title": "腾讯手机管家安全设置",
          "select": false,
          "desc": [
            {
              "desc": "打开<span>【腾讯手机管家】</span>，点击<span>【安全检测】</span>",
              "img": require("../assets/tencent-image1.png")
            },
            {
              "desc": "继续下一步，点击<span>【处理】</span>",
              "img": require("../assets/meizu-image2.png")
            },
            {
              "desc": "然后点击<span>【移入信任区】</span>",
              "img": require("../assets/tencent-image3.png")
            },
            {
              "desc": "点击<span>【确认】</span>后，白名单添加成功，再次杀毒 就不会提示了",
              "img": require("../assets/tencent-image4.png")
            }
          ]
        },
      ]
    }
  },
  methods: {

    closePage: function () {
      this.$router.go(-1);
    },

    onChangeIndex: function (index) {
      this.checkIndex = index;
    }
  },
  created() {

  },
  mounted: function () {

  }
}
</script>

<style>

.dot span {
  line-height: 50px;
  vertical-align: middle;
}

.item-card {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: #FFFFFF;
  border-radius: 80px;
  width: 90%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
  height: 60px;
  color: #333333;
  font-size: 18px;
}

.item-desc-item {
  line-height: 38px;
}

.item-desc-item span {
  color: red;
}

</style>
