<template>
  <div class="mobile">

    <div
        style="position: absolute;top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: 100%;display: flex;align-items:center;flex-direction: column;background-color: rgba(0,0,0,0.3)">

      <img src="../assets/ic_app_logo.png" width="60Px" style="border-radius: 10Px;margin-top: 140Px;">

      <div style="display: flex;flex-direction: column;margin-left: 10Px;color: whitesmoke">
        <div style="font-size: 26Px;margin-top: 20Px;font-weight: bold">娃娃脸短视频</div>
        <div style="font-size: 18Px;margin-top: 20Px;">让你快乐每一天</div>
      </div>

      <img src="../assets/title-mobile.png" width="90%" style="margin-top: 70Px">


      <!--    <img v-else src="https://apk.zeng-fanzhi.com/seyou/bgdt.gif" style="width: 100%;height: 100%;object-fit: cover">-->

      <!--      <div style="background-color: #FFFFFF;padding: 8Px;border-radius: 5Px;margin-top: 20Px;" v-qr="options"-->
      <!--           v-if="options"/>-->

      <!--      <div style="width: 100%;">-->
      <!--        <div-->
      <!--            style="display: flex;flex-direction: row;align-items: center;justify-content: start;border: white solid 2Px;width: 100%">-->
      <!--          <img src="../assets/ic_ios_logo.png" width="20" height="20"> <span-->
      <!--            style="margin-left: 45Px;color: #282828;font-size: 16Px;font-weight: bold">IOS 下载</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--    <div v-if="isIphone === true" class="download-btn" v-clipboard:copy="copyValue"-->
      <!--         v-on:click="downloadIOSClick">-->
      <!--      <img src="../assets/ic_ios_logo.png" width="20">-->
      <!--      <span>IOS下载</span>-->

      <!--    </div>-->

      <a v-if="h5Url" :href="h5Url" class="download-btn-h5" v-clipboard:copy="copyValue"
         style="display: flex;flex-direction: row;align-items: center;justify-content: center;color: #333333;font-size: 16Px;font-weight: bold;">
        在线观看
      </a>

      <a v-if="webClip && isIphone" class="download-btn-h5" v-clipboard:copy="copyValue"
         v-clipboard:success="goDownloadWebClipPage"
         style="display: flex;flex-direction: row;align-items: center;justify-content: center;color: white;font-size: 16Px;font-weight: bold;">
        <img src="../assets/ic_ios_logo.png" width="20" height="20"> <span
          style="margin-left: 15Px;color: #333333;font-size: 16Px;font-weight: bold">IOS轻量版 (推荐)</span>
      </a>

      <a v-if="isIphone && iosDownloadUrl" :href="iosDownloadUrl" class="download-btn" v-clipboard:copy="copyValue"
         style="display: flex;flex-direction: row;align-items: center;justify-content: start;">
        <img src="../assets/ic_ios_logo.png" width="20" height="20"> <span
          style="margin-left: 35Px;color: #333333;font-size: 16Px;font-weight: bold">IOS 下载</span>
      </a>

      <a v-if="!isIphone" class="download-btn-h5" v-clipboard:copy="copyValue"
         v-clipboard:success="goDownloadAndroidResume"
         style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
        <img src="../assets/ic_android_logo.png" width="20" height="20">
        <span style="margin-left:15Px;color: #333333;font-size: 16Px;font-weight: bold">Android下载</span>
      </a>

      <a v-if="!isIphone" :href="iosDownloadUrl" class="download-btn" v-clipboard:copy="copyValue"
         style="display: flex;flex-direction: row;align-items: center;justify-content: start;">
        <img src="../assets/ic_android_logo.png" width="20" height="20">
        <span style="margin-left:10Px;color: #333333;font-size: 16Px;font-weight: bold">Android下载 (备份)</span>
      </a>


    </div>

    <a :href="tgUrl"
       style="border-radius: 4Px; position: absolute;right: 10Px;top:10Px;height: 20Px;color:white;font-size: 14Px;background:linear-gradient(#FF2E8B,#FF277A);padding: 5Px 10Px;display: flex;flex-direction: row;align-items: center;">
      <img src="../assets/tg.png" width="13">
      <span style="margin-left: 10Px;">商务合作</span>
    </a>

    <DownLoadForWX v-if="isOpenInWx"/>

  </div>

</template>

<script>
import DownloadForWX from "./DownloadForWX";
import DownLoadForWX from "./DownloadForWX";

window.HELP_IMPROVE_VIDEOJS = false;
export default {
  name: "DownLoadForMobile",
  components: {DownLoadForWX},
  comments: {
    DownloadForWX
  },
  data() {
    return {
      isIphone: false,
      isOpenInWx: false,
      iosDownloadUrl: null,
      androidDownloadUrl: "",
      h5Url: null,
      tgUrl: null,
      webClip: null,
      copyValue: "",
      inviteCode: ""
    }
  },
  methods: {


    checkIsIphone: function () {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        console.log("ios");
        return true;
      } else {
        console.log("andriod");
        return false;
      }
    },

    showGroupListDialog: function (show) {
      this.showGroupList = show;
    },

    goDownloadWebClipPage: function () {
      window.location.href = this.webClip;
      this.$router.push({
        name: "DownLoadForWebClip",
        params: {
          h5Url: this.webClip,
          copyValue: this.copyValue
        }
      })
    },

    goDownloadAndroidResume: function () {
      window.location.href = this.androidDownloadUrl;
      this.$router.push({
        name: "DownLoadAndroidResume",
      })
    },

    isWeChat: function () {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },

    dateTime2strNow: function () {
      var data = new Date();
      console.log(data.getMonth());
      var Y = data.getFullYear();
      var M = data.getMonth() + 1;
      var D = data.getDate();
      return Y + (M < 10 ? '0' + M : M) + (D < 10 ? '0' + D : D);
    },
  },

  created() {

    this.isIphone = this.checkIsIphone();

    this.isOpenInWx = this.isWeChat();

    // if (this.$route.query.channel != null) {
    //   this.copyValue = "channel://" + this.$route.query.channel;
    // }
    if (this.$route.query.share != null) {
      this.copyValue = "_c://" + this.$route.query.share;
    }else if (this.$route.query.s != null){
      this.copyValue = "_c://" + this.$route.query.s;
    }else{
      this.copyValue = window.location.href;
    }

    console.log("this.$route.query.share")
    console.log(this.$route.query.share)
    console.log("this.copyValue")
    console.log(this.copyValue)

    this.$axios.get('/config.json').then(res => {
      console.log(res);
      // var dateTimeStr = this.dateTime2strNow();
      // var androidFileName = md5('main.apk' + dateTimeStr)
      // var webcilpFileName = md5('main.config' + dateTimeStr)
      this.androidDownloadUrl = res.data.android_url;
      this.iosDownloadUrl = res.data.ios_url;
      this.h5Url = res.data.h5_url;
      this.tgUrl = res.data.telegram_url;
      this.webClip = res.data.webclip_url;
    });

  },
  mounted: function () {

  }
}
</script>

<style>

.mobile {
  width: 100%;
  height: 100%;
  display: flex;
  background-position: left top;
  margin: 0 auto;
  background-color: #f8f8f8;
  background-image: url(../assets/bg-mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
}


.download-btn-h5 {
  height: 90px;
  background: white;
  border-radius: 45px;
  position: absolute;
  bottom: 179px;
  left: 0;
  right: 0;
  margin-left: 145px;
  margin-right: 145px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.download-btn-h5 img {
  width: 39px;
  height: 47px;
}

.download-btn-h5 span {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 500;
  margin-left: 23px;
}

.download-btn {
  height: 90px;
  background: white;
  border-radius: 45px;
  position: absolute;
  bottom: 69px;
  left: 0;
  right: 0;
  padding-left: 97px;
  margin-left: 145px;
  margin-right: 145px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.download-btn img {
  width: 39px;
  height: 47px;
}

.download-btn span {
  font-size: 34px;
  font-family: PingFang SC;
  font-weight: 500;
  margin-left: 53px;
  color: #FFFFFF;
}

</style>
