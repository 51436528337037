<template>
	<router-view />
</template>
<script type="text/javascript">

	export default {
		name: 'app',
	}
</script>
<style>
	/* #app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #FFFFFF;
		font-size: 0;
		background-color: red;
	} */
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
</style>
